<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">이전 페이지</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								아이디 찾기
							</h2>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<!-- 휴대폰 번호 -->
								<div class="frms__item">
									<h3 class="frms__title">휴대폰 번호</h3>
									<div class="frms__wrap">
										<input type="tel" class="ipt-frm" value="01012345678">
										<div class="frms__btns">
											<button type="button" class="btn-frmreset">
												<i class="icon icon-closesmall">폼 리셋</i>
											</button>
											<button type="button" class="btn btn-medium btn-secondary active">인증요청</button>
										</div>
									</div>
								</div>
								<!-- 인증 번호 -->
								<div class="frms__item">
									<h3 class="frms__title">인증 번호</h3>
									<div class="frms__wrap">
										<input type="number" class="ipt-frm" >
										<div class="frms__btns">
											<span class="frms__time eng">
												02:59
											</span>
											<button type="button" class="btn btn-medium btn-secondary">재전송</button>
										</div>
									</div>
								</div>
								<!-- [D] 인증번호 불일치 시 -->
								<div class="frms__item">
									<h3 class="frms__title">인증 번호</h3>
									<div class="frms__wrap failed">
										<input type="number" class="ipt-frm" value="12345">
										<div class="frms__btns">
											<span class="frms__time eng">
												02:59
											</span>
											<button type="button" class="btn btn-medium btn-secondary active">재전송</button>
										</div>
									</div>
									<div class="frms__msg">
										인증 번호가 일치하지 않습니다.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn btn-disabled">아이디 찾기</button>
		</div>
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm">아이디 찾기</button>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>
